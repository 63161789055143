function onloadInitKiamoPosition() {
    if (window.matchMedia('(max-width: 1024px)').matches) {
        let bodyEl = document.body;
        let cssVariableBottomPosition = getComputedStyle(bodyEl).getPropertyValue('--kiamo-bottom-position');

        // Element en bas dans le panier
        let cartResume = document.querySelector('.summary__total.-mobile');
        // Element en bas en fiche produit 
        let cartWrapper = document.querySelector('.fiche-produit-wrapper #cart-wrapper')
        // Element en bas dans le configurateur
        let configuratorResume = document.querySelector('.configurator .product__informations.-mobile')

        if ($(cartResume).length > 0) {
            // Panier
            // On initialise un mutation observer car le chat doit passer en bas de page lorsque le menu panier n'est plus sticky
            const observerMenu = new MutationObserver((mutationsList, observer) => {
                mutationsList.forEach((mutation) => {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        if (cartResume.classList.contains('-nofix')) {
                            // Si le menu n'est pas sticky on le met à 10px du bas
                            bodyEl.style.setProperty('--kiamo-bottom-position', 10 + 'px');
                        } else {
                            // Si le menu est sticky on le met à 10px du menu
                            bodyEl.style.setProperty('--kiamo-bottom-position', cartResume.offsetHeight + 10 + 'px');
                            let resumeDetails = cartResume.querySelector('.total__details');

                            const observerMenuOpen = new MutationObserver((mutationsList, observer) => {
                                mutationsList.forEach((mutation) => {
                                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                                        if (resumeDetails.classList.contains('-active')) {
                                            // Si on déplie le menu, on fait suivre le chat
                                            let heightResumeDetails = 0;
                                            // Au moment du clic le menu n'est pas déployé car transition de .5s alors on parcours les enfants et on determine la taille du menu deplié
                                            for (let i = 0; i < resumeDetails.children.length; i++) {
                                                heightResumeDetails += resumeDetails.children[i].offsetHeight;
                                            }
                                            bodyEl.style.setProperty('--kiamo-bottom-position', cartResume.offsetHeight + heightResumeDetails + 10 + 'px');
                                        } else {
                                            // Si replie le menu, au moment du clic le menu ne sera pas replié, alors on enlève la hauteur de l'élément qui se replie
                                            let heightResumeWithoutDetails = cartResume.offsetHeight - resumeDetails.offsetHeight;
                                            bodyEl.style.setProperty('--kiamo-bottom-position', heightResumeWithoutDetails + 10 + 'px');
                                        }
                                    }
                                });
                            });

                            observerMenuOpen.observe(resumeDetails, { attributes: true, attributeFilter: ['class'] });
                        }
                    }
                });
            });

            observerMenu.observe(cartResume, { attributes: true, attributeFilter: ['class'] });

        } else if ($(cartWrapper).length > 0) {
            // FP
            if (window.matchMedia('(max-width: 640px)').matches) {
                if (cartWrapper.offsetHeight > 0 && cssVariableBottomPosition !== cartWrapper.offsetHeight + 10) {
                    bodyEl.style.setProperty('--kiamo-bottom-position', cartWrapper.offsetHeight + 10 + 'px');
                }
            }
        } else if ($(configuratorResume).length > 0) {
            // Configurateur
            if (configuratorResume.offsetHeight > 0 && cssVariableBottomPosition !== configuratorResume.offsetHeight + 10) {
                bodyEl.style.setProperty('--kiamo-bottom-position', configuratorResume.offsetHeight + 10 + 'px');
            }
        }
    }
}
function onLoadDetectIfChatLoaded() {
    checkIfElementExists('#kiamo-chat:not(.kc-hidden)', (element) => {
        let chat = element;
        chat.addClass('-initialized');

        let logOutBtn = chat.find('#kc-topbar-actions .kc-close');
        logOutBtn.remove();

        let topbarItems = $('#kc-topbar-actions a.kc-icon');
        topbarItems.click((e) => {
            e.preventDefault();
        })

        let minimizeButton = chat.find(".kc-icon.minimize");
        if (minimizeButton.length > 0) {
            minimizeButton[0].click();
        }

        onClickLogoMaximizeChat(chat);
        onChangeChat(chat);
        addPlaceholder(chat);
        onChangeConversation(chat);
        onClickChangeTypoSize(chat);
    })
}
function checkIfElementExists(selector, callback, interval = 100) {
    let element = $(selector);
    if (element.length > 0) {
        callback(element);
        return;
    }

    const intervalId = setInterval(() => {
        element = $(selector);
        if (element.length > 0) {
            clearInterval(intervalId);
            callback(element);
        }
    }, interval)
}
function onClickLogoMaximizeChat(chat) {
    let logo = $('#kc-logo');

    logo.on('click', () => {
        if (chat.hasClass('kc-small')) {
            let maximize = $('#kc-topbar-actions .kc-toggler.maximize');
            maximize[0].click();
        }
    })
}
function onChangeChat(chat) {
    var mut = new MutationObserver(function (mutations, mut) {
        // Ajouter des callbacks quand le chat change de class
        addPlaceholder(chat);
        onChangeConversation(chat);
        checkSatisfaction(chat);
        checkMessageDisabled(chat);
        onClickChangeTypoSize(chat);
    });
    mut.observe(chat[0], {
        'attributes': true
    });
}
function addPlaceholder(chat) {
    if (chat[0].classList.contains('kc-conversation')) {
        let message = $('#kc-message');
        if (message.length > 0) {
            message.attr('placeholder', 'Posez votre question...')
        }
    }
}
function onChangeConversation(chat) {
    if (chat.hasClass('kc-conversation')) {
        let conversation = $('#kc-message-list')
        var mut = new MutationObserver(function (mutations, mut) {
            checkSatisfaction(chat);
            checkMessageDisabled(chat);
            checkMessageUnavailable(chat);
        })

        mut.observe(conversation[0], {
            'attributes': true, 'childList': true, 'characterData': true,
        })
    }
}
function checkSatisfaction(chat, onload) {
    let kcRows = $('.kc-row');
    let satisfaction;
    kcRows.each(function () {
        if ($(this).find('.kc-col-6').find('.kc-btn').length == 5) {
            satisfaction = $(this);
            if (!satisfaction.hasClass('-satisfaction')) {
                satisfaction.addClass('-satisfaction');
            }

            hoverListenerStarsSatisfaction(satisfaction);
            satisfaction.on('click', (e) => {
                if ($(e.target).hasClass('kc-btn')) {
                    clickListenerStarsSatisfaction();
                }
            })
        }
    })
}
function hoverListenerStarsSatisfaction(satisfaction) {
    let stars = satisfaction.find('.kc-btn');
    stars.each((index, star) => {
        let starWrappersSelected = [];
        star.addEventListener('mouseover', () => {
            let starIndex = +star.id;

            for (let i = starIndex; i > 0; i--) {
                starWrappersSelected.push(satisfaction.find('#' + i));
            }

            starWrappersSelected.forEach((item) => {
                item.addClass('-hover')
            })
        })

        star.addEventListener('mouseout', () => {
            starWrappersSelected.forEach((item) => {
                item.removeClass('-hover')
            })
        })
    })
}

function clickListenerStarsSatisfaction() {
    checkIfElementExists('.kc-btn.kc-selected', (element) => {
        element.parent().prevAll().find('.kc-btn').addClass('kc-selected')
    })
}

function checkMessageUnavailable(chat) {
    let messages = chat.find('.kc-message-content');
    messages.each((index, item) => {
        console.log(item.textContent.includes('indisponibles'))
    })
}

function checkMessageDisabled(chat) {
    let message = chat.find('#kc-message');
    if (message.length > 0) {
        if (message[0].disabled) {
            message.attr('placeholder', '')
            message.parent().addClass('-disabled');
        }
    }
}

function onClickChangeTypoSize(chat) {
    if (chat.hasClass('kc-conversation')) {
        chat.find('#kc-body').css('font-size', 'inherit');
        return
    } else {
        checkIfElementExists('#kiamo-chat.kc-invite', (element) => {
            if (chat.hasClass('kc-invite')) {
                let plus = element.find('.kc-icon.kc-upper'), minus = element.find('.kc-icon.kc-lower'), chatBody = element.find('#kc-body'), gap = 0.063, fontSize = 0.688, maxFontSize = 1, minFontSize = 0.625;
                chatBody.css('font-size', fontSize + 'em');

                if (plus.length > 0 && minus.length > 0) {
                    plus.on('click', () => {
                        fontSize += gap;
                        fontSize >= maxFontSize ? fontSize = maxFontSize : '';
                        chatBody.css('font-size', fontSize + 'em');
                    })

                    minus.on('click', () => {
                        fontSize -= gap;
                        fontSize <= minFontSize ? fontSize = minFontSize : '';
                        chatBody.css('font-size', fontSize + 'em');
                    })
                }
            }
        })
    }
}

$(document).ready(() => {
    onloadInitKiamoPosition();
    onLoadDetectIfChatLoaded();
})