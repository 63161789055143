; (function ($, window, document, undefined) {
    'use-strict';

    Foundation.libs.promo = {
        name: "promo",
        version: "1.0.0",
        settings: {
            callback: function () { }
        },
        init: function (scope, method, options) {
            this.settings = this.settings || $.extend({}, this.defaults, (options || method));
            // à conserver

            this.bindings(method, options);
        },
        events: function () {
            this.initBindings();
        },
        initBindings: function () {

        },

        initHellobars: function () {
            $('.hellobars').addClass('loaded');
            // Fonction déclenchée si 1 hb ou +
            if ($('.hellobars').length != 0) {
                let height = 0
                for (let i = 0; i < $('.hellobars .hellobar').length; i++) {
                    // On attribut les style aux hb en fonction des données passées dans les data-attributes
                    $('.hellobars .hellobar')[i].style.background = `#${$('.hellobars .hellobar')[i].getAttribute('data-background')}`
                    $('.hellobars .hellobar')[i].children[0].style.color = `#${$('.hellobars .hellobar')[i].getAttribute('data-color')}`
                    let newHeight = $('.hellobars .hellobar')[i].getBoundingClientRect().height
                    newHeight > height ? height = newHeight : ''
                    // Si la barre a un compteur, nouvel élément 
                    if ($('.hellobars .hellobar')[i].getAttribute('data-counter') != '') {
                        $('.hellobars .hellobar p')[i].appendChild(document.createElement('class'))
                        $('.hellobars .hellobar p')[i].children[$('.hellobars .hellobar p')[i].children.length - 1].setAttribute('id', 'demo')
                        this.countDownHelloBars($('.hellobars .hellobar')[i].getAttribute('data-counter'), i)
                    }
                }
                $('.hellobars')[0].style.height = `${height}px`
                for (let i = 0; i < $('.hellobars .hellobar').length; i++) {
                    $('.hellobars .hellobar')[i].style.height = `${height}px`
                }

                if ($('.hellobars .hellobar').length > 1) {
                    // Si > 1 barre, la première prend .active
                    $('.hellobar')[0].classList.add('active')
                    // La div parente prend la taille de la hb active (permet de gérer les hb sur plusieurs lignes)
                    // Pour éviter de passer sur 2 lignes, 50 caractères espaces compris si counter, 65 caractères espaces compris si !counter
                    //$('.hellobars')[0].style.height = `${$('.hellobar.active')[0].getBoundingClientRect().height}px`
                    var translateY = 0
                    let j = 0
                    // Les hb tournent toutes les 5secondes
                    setInterval(() => {
                        if (j == $('.hellobar').length - 1) {
                            translateY = 0
                            j = 0
                            $('.hellobars .hellobar.active')[0].classList.remove('active')
                            $('.hellobars .hellobar')[j].classList.add('active')
                            //$('.hellobars')[0].style.height = `${$('.hellobar.active')[0].getBoundingClientRect().height}px`
                            $('.hellobars__wrapper')[0].style.transform = `translateY(${translateY}px)`
                        } else {
                            let activeBarreHeight = $('.hellobars .hellobar.active')[0].getBoundingClientRect().height
                            translateY -= activeBarreHeight
                            $('.hellobars__wrapper')[0].style.transform = `translateY(${translateY}px)`
                            j++
                            $('.hellobars .hellobar.active')[0].classList.remove('active')
                            $('.hellobars .hellobar')[j].classList.add('active')
                            //$('.hellobars')[0].style.height = `${$('.hellobar.active')[0].getBoundingClientRect().height}px`
                        }
                    }, 5000)
                } else {
                    $('.hellobar')[0].classList.add('active')
                }
                // Problème d'affichage cart -> points relais & changement adresse 
                //if ($('#choose-withdrawal-point-wrapper')[0] != undefined) {
                //    // Observe un changement de class
                //    var observer = new MutationObserver(function (mutations) {
                //        mutations.forEach(function (mutation) {
                //            let dims = $('.hellobars')[0].getBoundingClientRect()
                //            // Margin top de la taille de la barre
                //            if ($('#choose-withdrawal-point-wrapper')[0].classList.contains('-active')) {
                //                $('#choose-withdrawal-point-wrapper')[0].style.margin = `${dims.top + dims.height > -1 ? dims.top + dims.height : 0}px 0 0 0`
                //            }
                //            //else if ($('#choose-address-wrapper')[0].classList.contains('-active')) {
                //            //    $('#choose-address-wrapper')[0].style.margin = `${dims.top + dims.height > -1 ? dims.top + dims.height : 0}px 0 0 0`
                //            //}
                //        });
                //    });
                //    var config = { attributes: true, childList: true, characterData: true };
                //    observer.observe($('#choose-withdrawal-point-wrapper')[0], config);
                //    //observer.observe($('#choose-address-wrapper')[0], config);
                //}
                // Problème d'affichage de la nav mobile
                //if (window.innerWidth <= 1024) {
                //    var observer = new MutationObserver(function (mutations) {
                //        mutations.forEach(function (mutation) {
                //            if ($('.nav-burger')[0].classList.contains('open')) {
                //                $('.navigation.-active ul.navigation__list')[0].style.top = `${$('.hellobars')[0].getBoundingClientRect().top + $('.hellobars')[0].getBoundingClientRect().height + 50}px`
                //            }
                //        });
                //    });
                //    var config = { attributes: true, childList: true, characterData: true };
                //    $('.nav-burger').length > 0
                //        ? observer.observe($('.nav-burger')[0], config)
                //        : ''
                //}

                $(".close-hellobars").click(function (e) {
                    e.preventDefault();
                    $.ajax({
                        url: "/Navigation/CloseHelloBars",
                    }).done(function () {
                        $(".hellobars").slideUp();
                        var hostname = "." + window.location.hostname.replace("www.", "");
                        document.cookie = "MatyHelloBarsDisplayed=; expires = Thu, 01 Jan 1970 12:00:00 GMT;path=/;domain=" + hostname;
                    });

                    //$('.navigation.-active ul.navigation__list')[0].style.top = "${$('.hellobars')[0].getBoundingClientRect().top + 50}px"
                });
            }

        },

        countDownHelloBars: function (date, index) {
            // Set the date we're counting down to
            var countDownDate = new Date(date).getTime();

            // Update the count down every 1 second
            var x = setInterval(function () {
                var now = new Date().getTime();
                var distance = countDownDate - now;

                var barres = document.querySelectorAll('.hellobars .hellobar')

                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                days == '0' ? days = '' : days = days + 'j&nbsp;'
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + 'h&nbsp;';
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) + 'm&nbsp;';
                var seconds = Math.floor((distance % (1000 * 60)) / 1000) + 's';

                barres[index].children[0].children[barres[index].children[0].children.length - 1].innerHTML = days + hours + minutes + seconds;

                if (distance < 0) {
                    barres[index].children[0].children[barres[index].children[0].children.length - 1].innerHTML = '';
                    clearInterval(x)
                }
            }, 1000)
        }
    }
}(jQuery, this, this.document));

$(document).ready(function () {
    Foundation.libs.promo.initHellobars();
});